import React from 'react'
import styled from 'styled-components'
import {graphql} from 'gatsby'
import PropTypes from 'prop-types'

import {PageHeader, MetaData, Layout, PostCard} from '../components'
import {BoxedContainer, Section} from '../elements'

const BlogPage = ({data}) => {
  const posts = data.allGhostPost.edges

  return (
    <Layout>
      <MetaData data={data.currentPage} />
      <PageHeader title="Blog" />
      <Section padding="both">
        <BoxedContainer>
          <Posts>
            {posts.map(post => (
              <PostCard post={post.node} key={post.node.id} />
            ))}
          </Posts>
        </BoxedContainer>
      </Section>
    </Layout>
  )
}

export default BlogPage

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

const Posts = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    grid-gap: 60px;
  }
`

export const query = graphql`
  {
    currentPage: ghostPage(slug: {eq: "blog"}) {
      ...GhostMetaPageFields
    }
    allGhostPost(
      filter: {primary_tag: {slug: {eq: "blog"}}}
      sort: {order: DESC, fields: [published_at]}
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
  }
`
